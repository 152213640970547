import styled from 'styled-components/macro'
import { useMediaQuery } from 'react-responsive'

const Container = styled.footer<{ isMobile?: boolean }>`
  background: #000000;
  color: #f8f9fc;
  padding: ${props => (props.isMobile ? '1rem' : '1.5rem 2rem')};
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  position: relative;
  font-size: 0.8rem;
`
const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  color: #999999;

  strong {
    color: white;
  }

  a {
    margin-top: 0.5rem;
    color: white;
    width: fit-content;
  }
`

function Footer() {
  const isMobile = useMediaQuery({ maxWidth: 650 })

  return (
    <Container isMobile={isMobile}>
      <FlexCol>
        <strong>©Design Horizon & Development 2025</strong>
        <span>Tous droits réservés</span>
        <a href="/mentions-legales">Mentions légales</a>
      </FlexCol>
      <FlexCol>
        <span>55 rue Saint Cléophas</span>
        <span>Crealead - Design Horizon</span>
        <span>34070 Montpellier</span>
        <a href="mailto:hello@designhorizon.io">hello@designhorizon.io</a>
      </FlexCol>
    </Container>
  )
}

export default Footer
